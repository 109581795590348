import PlatformStars from "components/PlatformStars/PlatformStars";
import dayjs from "dayjs";
import { typeObject } from "helpers/getObjectByType";
import "./WidgetCardCentered.scss";
import verifiedSVG from "icons/verified.svg";
import parse from "html-react-parser";

const WidgetCardCentered = ({ data, widgetStyle }) => {
  return (
    <div
      style={{
        background: widgetStyle.backgroundColor
          ? widgetStyle.backgroundColor
          : "#efefef",
        borderColor: widgetStyle.borderColor
          ? widgetStyle.borderColor
          : "#efefef",
        borderWidth: widgetStyle.borderWeight
          ? parseInt(widgetStyle.borderWeight)
          : 0,
        padding: widgetStyle.padding ? parseInt(widgetStyle.padding) : 16,
        borderRadius: widgetStyle.borderRadius
          ? parseInt(widgetStyle.borderRadius)
          : 4,
        boxShadow:
          widgetStyle?.dropShadow === "show" &&
          "1px 1px 7px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.05)",
      }}
      className={
        "widget-card-centered" +
        (widgetStyle.mouseAnimation === "yes" ? " has-animation" : "") +
        ` bg-opacity-${parseInt(widgetStyle.backgroundOpacity)}`
      }
    >
      <div className="w-16 h-16 rounded-full mr-3 widget-img">
        {data?.image ? (
          <img className="w-full h-full rounded-full" src={data.image} alt="" />
        ) : (
          <div className="w-full h-full text-white bg-blue-500 rounded-full flex items-center font-semibold justify-center text-xl">
            {data?.name?.substring(0, 1)}
          </div>
        )}
        {widgetStyle.platformLogos === "show" && (
          <img
            src={typeObject[data.type]?.miniImage}
            className="h-6 w-6 p-1 ml-3 platform-icon object-contain"
            style={{ maxWidth: 110 }}
            alt="platform"
          />
        )}
      </div>
      <div className="flex items-center justify-center my-2 text-center">
        <div className="flex items-center">
          <div>
            <div
              className="text-black font-bold mb-1"
              style={{
                color: widgetStyle.nameColor,
                fontSize: widgetStyle.nameSize
                  ? parseInt(widgetStyle.nameSize)
                  : 18,
              }}
            >
              {data.name}
            </div>
            <div className="text-xs text-gray-500">
              {dayjs(data.date).format("MMM DD, YYYY.")}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        {widgetStyle.platformStars === "show" ? (
          <PlatformStars rating={data?.rating} type={data?.type} />
        ) : (
          <div className="h-2"></div>
        )}
        {data?.verified && widgetStyle.verifiedIcon === "show" && (
          <div className="flex items-center ml-2" title="Verified">
            <img src={verifiedSVG} alt="Verified" />
            {/* <div className="text-xs text-green-600 ml-2">Verified</div> */}
          </div>
        )}
      </div>
      <div
        className={
          "text-gray-600 widget-card__description text-" + widgetStyle.textAlign
        }
        style={{
          color: widgetStyle.reviewColor,
          fontSize: widgetStyle.reviewSize
            ? parseInt(widgetStyle.reviewSize)
            : 14,
        }}
      >
        {parse(data?.description || "")}
      </div>
    </div>
  );
};

export default WidgetCardCentered;
