import PlatformStars from "components/PlatformStars/PlatformStars";
import WidgetPlatformLogo from "components/WidgetPlatformLogo/WidgetPlatformLogo";
import { findOverall } from "helpers/findOverall";
import { getPlatformURL } from "helpers/generateUrls";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./WidgetHtmlBadge.scss";
import en from "labels/en.json";
import se from "labels/se.json";
import nl from "labels/nl.json";
import de from "labels/de.json";
import fr from "labels/fr.json";

const WidgetHtmlBadge = ({ widgetStyle, data, widget = null }) => {
  const userFromStore = useSelector((state) => state.auth.account);
  const [user, setUser] = useState(userFromStore);
  const [lang, setLang] = useState(en);
  useEffect(() => {
    if (widget) {
      setUser(widget);
    } else {
      setUser(userFromStore);
    }
    // eslint-disable-next-line
  }, [widget, userFromStore]);

  useEffect(() => {
    if (widgetStyle?.language === "se") {
      setLang(se);
    } else if (widgetStyle?.language === "nl") {
      setLang(nl);
    } else if (widgetStyle?.language === "de") {
      setLang(de);
    } else if (widgetStyle?.language === "fr") {
      setLang(fr);
    } else {
      setLang(en);
    }
  }, [widgetStyle?.language]);

  return (
    <a
      href={getPlatformURL(user, widgetStyle?.reviewSources)}
      target="_blank"
      rel="noopener noreferrer"
      className={"flex justify-center widget-badge"}
      style={{
        background: widget ? "transparent" : widgetStyle.outerBackgroundColor,
      }}
    >
      <div
        className={
          "widget-badge-inner flex flex-col items-center widget-badge-big" +
          (widgetStyle.mouseAnimation === "yes" ? " has-animation" : "") +
          ` bg-opacity-${parseInt(widgetStyle.backgroundOpacity)}`
        }
        style={{
          background: widgetStyle.backgroundColor
            ? widgetStyle.backgroundColor
            : "#efefef",
          borderColor: widgetStyle.borderColor
            ? widgetStyle.borderColor
            : "#efefef",
          borderWidth: widgetStyle.borderWeight
            ? parseInt(widgetStyle.borderWeight)
            : 0,
          padding: widgetStyle.padding ? parseInt(widgetStyle.padding) : 34,
          borderRadius: widgetStyle.borderRadius
            ? parseInt(widgetStyle.borderRadius)
            : 4,
          boxShadow:
            widgetStyle?.dropShadow === "show" &&
            "1px 1px 7px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="flex items-center">
          <div
            style={{
              color: widgetStyle.nameColor,
              fontSize: widgetStyle.nameSize
                ? parseInt(widgetStyle.nameSize)
                : 20,
            }}
            className="mr-4"
          >
            <strong>
              {findOverall(
                user,
                widgetStyle?.reviewSources === "all"
                  ? "overall"
                  : widgetStyle?.reviewSources
              ).rating || "N/A"}
            </strong>{" "}
            {lang["WIDGET_OUT_OF"]} 5
          </div>
          <PlatformStars
            rating={
              findOverall(
                user,
                widgetStyle?.reviewSources === "all"
                  ? "overall"
                  : widgetStyle?.reviewSources,
                widget
              ).rating
            }
            googleSize={24}
            type={
              widgetStyle?.reviewSources === "all"
                ? "trustbucket"
                : widgetStyle?.reviewSources
            }
          />
        </div>
        <div
          className="flex items-center mt-5"
          style={{ color: widgetStyle.reviewColor }}
        >
          <div
            style={{
              color: widgetStyle.reviewColor,
              fontSize: widgetStyle.reviewSize
                ? parseInt(widgetStyle.reviewSize)
                : 15,
            }}
          >
            <span>{lang["WIDGET_BASED_ON"]} </span>
            <span className="underline">
              {
                findOverall(
                  user,
                  widgetStyle?.reviewSources === "all"
                    ? "overall"
                    : widgetStyle?.reviewSources
                ).count
              }{" "}
              {lang["WIDGET_REVIEWS"]}
            </span>{" "}
            <span>|</span>{" "}
          </div>
          <div className="ml-1.5">
            <WidgetPlatformLogo
              size="small"
              platform={widgetStyle?.reviewSources}
            />{" "}
          </div>
        </div>
      </div>
    </a>
  );
};

export default WidgetHtmlBadge;
