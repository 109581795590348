import ModalBasic from "components/ModalBasic";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeCompany, getCompanies, updateAdminSelectedBusiness } from "store/actions/auth";

const ChangeAccountModal = ({
  changeAccOpen,
  setChangeAccOpen,
  setBusinessModalOpen,
}) => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const adminId = localStorage.getItem("id");

  const user = useSelector((state) => state.auth.account);
  const companies = useSelector((state) => state.auth.companies);
  const [selectedProfile, setSelecterProfile] = useState();



  useEffect(() => {
    if (user) {
      setSelecterProfile(user.selectedCompany);
      if (user.selectedBusinessId == 'null') {
        setChangeAccOpen(true);
      }
      // console.log(user.selectedBusinessId);
      const isAvailable = companies.find(
        (company) => company.id === user.selectedBusinessId
      );

      if (isAvailable || user.selectedBusinessId) {
        dispatch(
          changeCompany(user.selectedBusinessId, () => {
            localStorage.setItem("selectedBusinessId", user.selectedBusinessId)
            setChangeAccOpen(false);
            // history.push("/dashboard");
          })
        );
      }

    }
  }, [user, setChangeAccOpen, companies, dispatch]);


  useEffect(() => {
    dispatch(getCompanies());
    // eslint-disable-next-line
  }, []);

  const submitChange = () => {
    if (!selectedProfile) {
      return;
    }
    dispatch(updateAdminSelectedBusiness(adminId, selectedProfile.id));

    dispatch(
      changeCompany(selectedProfile.id, () => {
        localStorage.setItem("selectedBusinessId", selectedProfile.id)
        setChangeAccOpen(false);
        // history.push("/dashboard");
      })
    );
  };

  const handleCancelClick = (e) => {
    if (!selectedProfile) {
      e.stopPropagation();
      return;
    }
    setChangeAccOpen(false);
  };

  const handleClose = () => {
    if (!selectedProfile) {

      return;
    }
    setChangeAccOpen(false);
  };

  return (
    <ModalBasic
      id="change-acc-modal"
      modalOpen={changeAccOpen}
      setModalOpen={handleClose}
      title="Choose business"
    >
      <div>
        {/* Modal content */}
        <div className="px-5 py-4">
          {user?.type === "appsumo" && (
            <div className="text-sm italic text-gray-600 mb-4">
              You have{" "}
              <strong>
                {user?.availableProCompanies === "unlimited"
                  ? "unlimited"
                  : user?.availableProCompanies - user?.companies.length > 0
                    ? user?.availableProCompanies - user?.companies.length
                    : 0}
              </strong>{" "}
              more companies to create on pro plan
            </div>
          )}
          {companies?.map((item, index) => (
            <div
              key={index}
              className={
                "rounded w-full p-3 flex items-center cursor-pointer overflow-hidden mb-2 " +
                (item.id === selectedProfile?.id
                  ? "border-blue-500 border-2"
                  : "border-2 border-gray-300")
              }
              onClick={() => setSelecterProfile(item)}
            >
              <span
                className={
                  "block w-4 h-4 rounded-full mr-5 " +
                  (item.id === selectedProfile?.id
                    ? "border-4 border-blue-500"
                    : "border-2 border-gray-500")
                }
                style={{ minWidth: 16 }}
              ></span>
              <div>
                <div className="flex items-center">
                  <div className="font-semibold text-sm mr-4 text-black">
                    {item.businessName}
                  </div>
                  <div className="italic text-gray-500 text-xs font-medium">
                    {item?.websiteURL}
                  </div>
                </div>

                <div className="text-xs text-gray-600 truncate w-full pr-3">
                  {item?.address}
                </div>
              </div>
            </div>
          ))}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setChangeAccOpen(false);
              setBusinessModalOpen(true);
            }}
            className="flex items-center cursor-pointer border border-gray-300 text-blue-500 py-4 px-5 text-sm font-medium bg-gray-50 rounded"
          >
            <span className="text-2xl mr-3">+</span>
            Add business
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4">
          <div className="flex flex-wrap justify-end space-x-2">
            <div
              className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
              // onClick={(e) => {
              //   e.stopPropagation();
              //   setChangeAccOpen(false);
              // }}
              onClick={handleCancelClick}
            >
              Cancel
            </div>
            <button
              type="submit"
              className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
              onClick={submitChange}
            >
              Switch profile
            </button>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
};

export default React.memo(ChangeAccountModal);
