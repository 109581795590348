import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";
import { setRatings } from "./ratings";

export const setTags = (payload) => {
  return {
    type: actionTypes.SET_TAGS,
    payload,
  };
};

export const getTags = () => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`tag`)
      .then((response) => {
        dispatch(setTags(response.data.data));
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const addTag = (keyword, autopopulate, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`tag`, { keyword, autopopulate })
      .then((response) => {
        dispatch(getTags());
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const deleteTag = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .delete(`tag/${id}`)
      .then((response) => {
        dispatch(getTags());
        dispatch(finishLoading());
        dispatch(successNotification(response.data.message));
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const assignTag = (tag, reviewId, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .post(`tag/assign`, { tagId: tag?._id, reviewId })
      .then((response) => {
        dispatch(getTags());
        dispatch(finishLoading());
        let previousRatings = getState().ratings?.ratings;
        let foundRating = previousRatings.find((el) => el?._id === reviewId);
        if (foundRating) {
          if (foundRating?.tags.find((el) => el?._id === tag?._id)) {
            let newArr = foundRating?.tags.filter((el) => el?._id !== tag?._id);
            foundRating = { ...foundRating, tags: newArr };
          } else {
            foundRating = { ...foundRating, tags: [...foundRating.tags, tag] };
          }
        }
        let newRatings = [];
        previousRatings.forEach((el) => {
          let newEl = el;
          if (el._id === reviewId) {
            newEl = foundRating;
          }
          newRatings.push(newEl);
        });
        dispatch(setRatings(newRatings));
        dispatch(successNotification(response.data.message));
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};
