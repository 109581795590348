import ModalBasic from "components/ModalBasic";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignTag } from "store/actions/tags";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";

const EditTagsModal = ({ tagModalOpen, setTagModalOpen, data }) => {
  const dispatch = useDispatch();
  const [assignedTags, setAssignedTags] = useState(data?.tags);

  const tags = useSelector((state) => state.tags.tags);

  const assignTagHandler = (tag) => {
    dispatch(assignTag(tag, data?._id));
    if (assignedTags.find((el) => el?._id === tag?._id)) {
      let newArr = assignedTags.filter((el) => el?._id !== tag?._id);
      setAssignedTags(newArr);
    } else {
      setAssignedTags((p) => [...p, tag]);
    }
  };

  return (
    <ModalBasic
      id="edit-tags-modal"
      modalOpen={tagModalOpen}
      setModalOpen={setTagModalOpen}
      title="Edit review tags"
      style={{ zIndex: 100 }}
    >
      <div>
        <div>
          {/* Modal content */}
          <div className="px-5 py-4">
            <section>
              {tags?.length > 0 && (
                <div className="text-sm mb-4">
                  Check or uncheck tags you want to use in selected review
                </div>
              )}
              <ul className="mb-5">
                {tags?.length === 0 && (
                  <div className="text-sm mb-4">
                    You currently don't have created tags, create them in
                    reviews filters
                  </div>
                )}
                {tags &&
                  tags.length > 0 &&
                  tags.map((item, index) => (
                    <li className="py-1 mb-1" key={index}>
                      <div className="flex items-center justify-between">
                        <label
                          className="flex items-center cursor-pointer"
                          key={index}
                        >
                          <input
                            type="checkbox"
                            className="form-checkbox w-6 h-6"
                            readOnly
                            checked={assignedTags?.find(
                              (el) => el?._id === item?._id
                            )}
                            onClick={() => assignTagHandler(item)}
                          />
                          <span className="text-sm font-medium ml-2">
                            {item?.keyword}
                          </span>
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            </section>
            <hr className="" />
          </div>
          {/* Modal footer */}
          <div className="px-5 py-4">
            <div className="flex flex-wrap justify-end space-x-2">
              <div
                className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setTagModalOpen(false);
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
};

export default React.memo(EditTagsModal);
