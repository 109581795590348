import InviteMemberModal from "components/InviteMemberModal/InviteMemberModal";
import TeamTable from "components/TeamTable/TeamTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembers } from "store/actions/auth";

export default function MyTeamPanel() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const teamMembers = useSelector((state) => state.auth.teamMembers);

  useEffect(() => {
    dispatch(getTeamMembers());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="flex items-center justify-between">
            <h2 className="text-xl sm:text-2xl text-gray-800 font-bold mb-5">
              Team members
            </h2>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(true);
              }}
              className="btn bg-blue-600 text-white transition-colors hover:bg-blue-700"
            >
              Invite member
            </button>
          </div>

          {/* General */}
          <section style={{ minHeight: "50vh" }}>
            <TeamTable
              total={teamMembers.length}
              loading={false}
              users={teamMembers}
            />

            {/* Pagination */}
            {/* <div className="mt-8">
  {!loading && users.length > 0 && (
    <CustomPagination
      lastPage={usersLastPage}
      page={page}
      setPage={setPage}
      total={usersTotal}
      pageSize={pageSize}
    />
  )}
</div> */}
          </section>
        </div>
      </div>
      <InviteMemberModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
}
