export const findOverall = (user, type = "overall", isWidget = null) => {
  let typeIndex = user?.selectedCompany?.ratings.findIndex(
    (el) => el.type === type
  );

  return {
    rating: user?.selectedCompany?.ratings[typeIndex]?.rating,
    count: user?.selectedCompany?.ratings[typeIndex]?.ratingCount,
  };
};

export const getStarLabel = (rating) => {
  if (rating <= 1.5) {
    return "Bad";
  } else if (rating > 1.5 && rating <= 2.9) {
    return "Poor";
  } else if (rating > 2.9 && rating <= 4) {
    return "Average";
  } else if (rating > 3.9 && rating < 4.4) {
    return "Great";
  } else {
    return "Excellent";
  }
};
