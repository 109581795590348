import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getRatingsCallback } from "store/actions/ratings";
import WidgetCard from "../../Cards/WidgetCard/WidgetCard";
import "./WidgetGrid.scss";
import WidgetCardCentered from "components/WidgetConfigurator/Cards/WidgetCardCentered/WidgetCardCentered";
import { findOverall } from "helpers/findOverall";
import PlatformStars from "components/PlatformStars/PlatformStars";
import WidgetPlatformLogo from "components/WidgetPlatformLogo/WidgetPlatformLogo";
import { getPlatformURL } from "helpers/generateUrls";
import reviewsDummy from "helpers/reviewsDummy.json";
import en from "labels/en.json";
import se from "labels/se.json";
import nl from "labels/nl.json";
import de from "labels/de.json";
import fr from "labels/fr.json";
import dayjs from "dayjs";
require("dayjs/locale/sv");
require("dayjs/locale/nl");
require("dayjs/locale/de");
require("dayjs/locale/fr");

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 100,
  sortField: "date",
  sortOrder: "desc",
};

const WidgetGrid = ({ widgetStyle, data, widget = null }) => {
  const dispatch = useDispatch();
  const userFromStore = useSelector((state) => state.auth.account);
  const [user, setUser] = useState(userFromStore);
  const [lang, setLang] = useState(en);

  useEffect(() => {
    if (widget) {
      setUser(widget);
    } else {
      setUser(userFromStore);
    }
    // eslint-disable-next-line
  }, [widget, userFromStore]);

  const [widgetReviews, setWidgetReviews] = useState([]);
  const ratingsFromWidget = useSelector((state) => state.widget.widgetRatings);

  useEffect(() => {
    if (widget) {
      if (widget?.object?.ratingFilter !== "all") {
        const includedRatings = widget?.object?.ratingFilter?.split(",");

        let filteredReviews = [];
        ratingsFromWidget?.forEach((el) => {
          if (includedRatings.some((el2) => parseInt(el2) === el?.rating)) {
            filteredReviews = [...filteredReviews, el];
          }
        });
        setWidgetReviews(filteredReviews);
      } else {
        setWidgetReviews(ratingsFromWidget);
      }
    } else {
      dispatch(
        getRatingsCallback(
          initialQueryParams,
          widgetStyle.reviewSources === "all" ? "" : widgetStyle.reviewSources,
          widgetStyle.ratingFilter === "all"
            ? ""
            : widgetStyle.ratingFilter?.split(","),
          (data) => setWidgetReviews(data)
        )
      );
    }
    // eslint-disable-next-line
  }, [widgetStyle.reviewSources, widgetStyle.ratingFilter, ratingsFromWidget]);

  useEffect(() => {
    if (widgetStyle?.language === "se") {
      setLang(se);
      dayjs.locale("sv");
    } else if (widgetStyle?.language === "nl") {
      setLang(nl);
      dayjs.locale("nl");
    } else if (widgetStyle?.language === "de") {
      setLang(de);
      dayjs.locale("de");
    } else if (widgetStyle?.language === "fr") {
      setLang(fr);
      dayjs.locale("fr");
    } else {
      setLang(en);
      dayjs.locale("en");
    }
  }, [widgetStyle?.language]);

  return (
    <div
      className="py-7 md:py-10 px-2 w-full max-w-7xl flex flex-col items-center mx-auto"
      style={{
        background: widget ? "transparent" : widgetStyle.outerBackgroundColor,
      }}
    >
      {widgetStyle.footerText === "show" && (
        <a
          href={getPlatformURL(user, widgetStyle?.reviewSources)}
          target="_blank"
          rel="noopener noreferrer"
          className="w-96 lg:mb-4 mb-5 flex flex-col items-center text-center carousel-left"
          style={{ color: widgetStyle.outerTextColor, fontSize: 15 }}
        >
          <div className="text-2xl mb-2.5 flex items-center">
            <span className="font-bold">
              {findOverall(
                user,
                widgetStyle?.reviewSources === "all"
                  ? "overall"
                  : widgetStyle?.reviewSources,
                widget
              ).rating || "N/A"}
            </span>
            <span className="mx-2 block">{lang["WIDGET_OUT_OF"]} 5</span>
            <span className="ml-2">
              <PlatformStars
                rating={
                  findOverall(
                    user,
                    widgetStyle?.reviewSources === "all"
                      ? "overall"
                      : widgetStyle?.reviewSources,
                    widget
                  ).rating
                }
                type={
                  widgetStyle?.reviewSources === "all"
                    ? "trustbucket"
                    : widgetStyle?.reviewSources
                }
              />
            </span>
          </div>

          <div className="my-2 flex items-center" style={{ fontSize: 15 }}>
            {lang["WIDGET_BASED_ON"]}{" "}
            <span className="font-semibold underline mx-1.5">
              {
                findOverall(
                  user,
                  widgetStyle?.reviewSources === "all"
                    ? "overall"
                    : widgetStyle?.reviewSources,
                  widget
                ).count
              }{" "}
              {lang["WIDGET_REVIEWS"]}
            </span>
            <span className="ml-1.5 mr-2">|</span>
            <WidgetPlatformLogo
              size="small"
              platform={widgetStyle?.reviewSources}
            />
          </div>
        </a>
      )}
      <div className="widget-grid w-full">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {widgetReviews?.length > 0
            ? widgetReviews.map(
                (item, index) =>
                  index <
                    (widgetStyle?.numberOfReviews
                      ? widgetStyle?.numberOfReviews
                      : 6) && (
                    <div className="" key={index}>
                      {widgetStyle.widgetType === "grid-1" ? (
                        <WidgetCard data={item} widgetStyle={widgetStyle} />
                      ) : (
                        <WidgetCardCentered
                          data={item}
                          widgetStyle={widgetStyle}
                        />
                      )}
                    </div>
                  )
              )
            : reviewsDummy.map(
                (item, index) =>
                  index <
                    (widgetStyle?.numberOfReviews
                      ? widgetStyle?.numberOfReviews
                      : 6) && (
                    <div className="" key={index}>
                      {widgetStyle.widgetType === "grid-1" ? (
                        <WidgetCard
                          data={{
                            ...item,
                            type:
                              widgetStyle?.reviewSources === "all"
                                ? "trustbucket"
                                : widgetStyle?.reviewSources,
                          }}
                          widgetStyle={widgetStyle}
                        />
                      ) : (
                        <WidgetCardCentered
                          data={item}
                          widgetStyle={widgetStyle}
                        />
                      )}
                    </div>
                  )
              )}
        </div>
      </div>
    </div>
  );
};

export default WidgetGrid;
