import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { getRatingsCallback } from "store/actions/ratings";
import WidgetCard from "../../Cards/WidgetCard/WidgetCard";
import "./WidgetSlider.scss";
// import WidgetCardCentered from "components/WidgetConfigurator/Cards/WidgetCardCentered/WidgetCardCentered";
import { findOverall } from "helpers/findOverall";
// import RatingStars from "components/RatingsStars/RatingsStars";
import PlatformStars from "components/PlatformStars/PlatformStars";
import WidgetPlatformLogo from "components/WidgetPlatformLogo/WidgetPlatformLogo";
import { getPlatformURL } from "helpers/generateUrls";
import reviewsDummy from "helpers/reviewsDummy.json";
import en from "labels/en.json";
import se from "labels/se.json";
import nl from "labels/nl.json";
import de from "labels/de.json";
import fr from "labels/fr.json";
import dayjs from "dayjs";
require("dayjs/locale/sv");
require("dayjs/locale/nl");
require("dayjs/locale/de");
require("dayjs/locale/fr");

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 100,
  sortField: "date",
  sortOrder: "desc",
};
const WidgetSlider = ({ widgetStyle, data, widget = null }) => {
  const dispatch = useDispatch();
  const userFromStore = useSelector((state) => state.auth.account);
  const [user, setUser] = useState(userFromStore);
  const [lang, setLang] = useState(en);

  useEffect(() => {
    if (widget) {
      setUser(widget);
    } else {
      setUser(userFromStore);
    }
    // eslint-disable-next-line
  }, [widget, userFromStore]);

  const [widgetReviews, setWidgetReviews] = useState(null);
  const ratingsFromWidget = useSelector((state) => state.widget.widgetRatings);

  useEffect(() => {
    if (widget) {
      if (widget?.object?.ratingFilter !== "all") {
        const includedRatings = widget?.object?.ratingFilter?.split(",");

        let filteredReviews = [];
        ratingsFromWidget?.forEach((el) => {
          if (includedRatings.some((el2) => parseInt(el2) === el?.rating)) {
            filteredReviews = [...filteredReviews, el];
          }
        });
        setWidgetReviews(filteredReviews);
      } else {
        setWidgetReviews(ratingsFromWidget);
      }
    } else {
      dispatch(
        getRatingsCallback(
          initialQueryParams,
          widgetStyle.reviewSources === "all" ? "" : widgetStyle.reviewSources,
          widgetStyle.ratingFilter === "all"
            ? ""
            : widgetStyle.ratingFilter?.split(","),
          (data) => setWidgetReviews(data)
        )
      );
    }

    // eslint-disable-next-line
  }, [widgetStyle.reviewSources, widgetStyle.ratingFilter, ratingsFromWidget]);

  SwiperCore.use([Navigation, Pagination, Autoplay]);

  useEffect(() => {
    if (widgetStyle?.language === "se") {
      setLang(se);
      dayjs.locale("sv");
    } else if (widgetStyle?.language === "nl") {
      setLang(nl);
      dayjs.locale("nl");
    } else if (widgetStyle?.language === "de") {
      setLang(de);
      dayjs.locale("de");
    } else if (widgetStyle?.language === "fr") {
      setLang(fr);
      dayjs.locale("fr");
    } else {
      setLang(en);
      dayjs.locale("en");
    }
  }, [widgetStyle?.language]);

  return (
    <div
      className="py-7 md:py-10 px-2 w-full max-w-6xl 2xl:max-w-7xl mx-auto"
      style={{
        background: widget ? "transparent" : widgetStyle.outerBackgroundColor,
      }}
      id={widgetStyle?.widgetType}
    >
      <div className="flex items-center lg:flex-row flex-col">
        {widgetStyle?.widgetType === "carousel" && (
          <a
            href={getPlatformURL(user, widgetStyle?.reviewSources)}
            target="_blank"
            rel="noopener noreferrer"
            className="w-96 lg:mb-0 mb-5 flex flex-col items-center text-center carousel-left"
            style={{ color: widgetStyle.outerTextColor, fontSize: 15 }}
          >
            <div className="text-2xl mb-2.5 flex items-center">
              <span className="font-bold">
                {findOverall(
                  user,
                  widgetStyle?.reviewSources === "all"
                    ? "overall"
                    : widgetStyle?.reviewSources,
                  widget
                ).rating || "N/A"}
              </span>
              <span className="mx-2 block">{lang["WIDGET_OUT_OF"]} 5</span>
              <span className="ml-2">
                <PlatformStars
                  rating={
                    findOverall(
                      user,
                      widgetStyle?.reviewSources === "all"
                        ? "overall"
                        : widgetStyle?.reviewSources,
                      widget
                    ).rating
                  }
                  type={
                    widgetStyle?.reviewSources === "all"
                      ? "trustbucket"
                      : widgetStyle?.reviewSources
                  }
                />
              </span>
            </div>

            <div className="my-2 flex items-center" style={{ fontSize: 15 }}>
              {lang["WIDGET_BASED_ON"]}{" "}
              <span className="font-semibold underline mx-1.5">
                {
                  findOverall(
                    user,
                    widgetStyle?.reviewSources === "all"
                      ? "overall"
                      : widgetStyle?.reviewSources,
                    widget
                  ).count
                }{" "}
                {lang["WIDGET_REVIEWS"]}
              </span>
              <span className="ml-1.5 mr-2">|</span>
              <WidgetPlatformLogo
                size="small"
                platform={widgetStyle?.reviewSources}
              />
            </div>
          </a>
        )}

        <div
          className={
            "widget-slider w-full" +
            (widgetStyle?.widgetType === "carousel" ? " widget-carousel" : "")
          }
          // style={{
          //   maxWidth:
          //     widgetStyle?.widgetType === "carousel"
          //       ? "calc(100% - 208px)"
          //       : "100%",
          // }}
        >
          <div className="flex items-center">
            {widgetStyle.navigationArrows === "show" && (
              <>
                <div className="widget-slider-arrow arrow-prev">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </div>
              </>
            )}
            {widgetReviews && (
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{ delay: 6000 }}
                navigation={{
                  nextEl: `#${widgetStyle?.widgetType} .widget-slider-arrow.arrow-next`,
                  prevEl: `#${widgetStyle?.widgetType} .widget-slider-arrow.arrow-prev`,
                }}
                pagination={{
                  el: ".widget-slider-pagination",
                  clickable: true,
                  renderBullet: (index, className) => {
                    return '<span className="' + className + '"></span>';
                  },
                }}
                breakpoints={
                  widgetStyle?.widgetType === "carousel"
                    ? {
                        900: {
                          slidesPerView:
                            widgetReviews?.length >= 2 ||
                            widgetReviews?.length === 0
                              ? 2
                              : widgetReviews?.length,
                        },
                      }
                    : {
                        900: {
                          slidesPerView:
                            widgetReviews?.length >= 3 ||
                            widgetReviews?.length === 0
                              ? 3
                              : widgetReviews?.length,
                        },
                        700: {
                          slidesPerView:
                            widgetReviews?.length >= 2 ||
                            widgetReviews?.length === 0
                              ? 2
                              : widgetReviews?.length,
                        },
                      }
                }
              >
                {widgetReviews?.length > 0
                  ? widgetReviews.map(
                      (item, index) =>
                        index <
                          (widgetStyle?.numberOfReviews
                            ? widgetStyle?.numberOfReviews
                            : 10) && (
                          <SwiperSlide key={index}>
                            <WidgetCard data={item} widgetStyle={widgetStyle} />
                          </SwiperSlide>
                        )
                    )
                  : reviewsDummy.map(
                      (item, index) =>
                        index <
                          (widgetStyle?.numberOfReviews
                            ? widgetStyle?.numberOfReviews
                            : 10) && (
                          <SwiperSlide key={index}>
                            <WidgetCard
                              data={{
                                ...item,
                                type:
                                  widgetStyle?.reviewSources === "all"
                                    ? "trustbucket"
                                    : widgetStyle?.reviewSources,
                              }}
                              widgetStyle={widgetStyle}
                            />
                          </SwiperSlide>
                        )
                    )}
              </Swiper>
            )}
            {widgetStyle.navigationArrows === "show" && (
              <>
                <div className="widget-slider-arrow arrow-next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevrons-right"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </div>
              </>
            )}
          </div>
          <div
            className={
              "widget-slider-pagination dots--" + widgetStyle.navigationDots
            }
          ></div>
          {widgetStyle.footerText === "show" &&
            widgetStyle?.widgetType !== "carousel" && (
              <>
                <a
                  href={getPlatformURL(user, widgetStyle?.reviewSources)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 text-center flex items-center justify-center"
                  style={{ color: widgetStyle.outerTextColor, fontSize: 15 }}
                >
                  <span className="font-bold mr-1.5">
                    {findOverall(
                      user,
                      widgetStyle?.reviewSources === "all"
                        ? "overall"
                        : widgetStyle?.reviewSources,
                      widget
                    ).rating || "N/A"}{" "}
                  </span>{" "}
                  {lang["WIDGET_OUT_OF"]} 5
                  <div className="mx-4 md:block hidden">
                    <PlatformStars
                      type={
                        widgetStyle?.reviewSources === "all"
                          ? "trustbucket"
                          : widgetStyle?.reviewSources
                      }
                      rating={
                        findOverall(
                          user,
                          widgetStyle?.reviewSources === "all"
                            ? "overall"
                            : widgetStyle?.reviewSources,
                          widget
                        ).rating
                      }
                    />
                  </div>
                  <span className="mr-1 md:block hidden">
                    {lang["WIDGET_BASED_ON"]}
                  </span>
                  <span className="md:hiddden block ml-1">{"("}</span>
                  <span className="underline">
                    {
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).count
                    }{" "}
                    {lang["WIDGET_REVIEWS"]}
                  </span>
                  <span className="md:hiddden block">{")"}</span>
                  <span className="mx-2"> | </span>
                  <div
                    className="flex flex-col items-center"
                    style={{ color: widgetStyle.outerTextColor }}
                  >
                    <WidgetPlatformLogo
                      size="small"
                      platform={widgetStyle?.reviewSources}
                    />
                  </div>
                </a>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default WidgetSlider;
