import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loader from "hoc/Loader/Loader";
import { useSelector } from "react-redux";

import Dashboard from "containers/pages/Dashboard";
import ConnectPlatforms from "containers/pages/ConnectPlatforms";
import ManageReviews from "containers/pages/ManageReviews";
import Profile from "containers/pages/settings/Profile";
import Plans from "containers/pages/settings/Plans";
import Billing from "containers/pages/settings/Billing";
import Feedback from "containers/pages/settings/Feedback";
import MyNotifications from "containers/pages/settings/MyNotifications";
import BusinessInfo from "containers/pages/settings/BusinessInfo";
import ConnectSource from "containers/pages/connect-source/ConnectSource";
import CollectOverview from "containers/pages/collect-reviews/CollectOverview";
import CreateCampaign from "containers/pages/collect-reviews/CreateCampaign";
import AutomaticCollection from "containers/pages/collect-reviews/AutomaticCollection";
import InvitationSettings from "containers/pages/collect-reviews/InvitationSettings";
import TemplateEditor from "containers/pages/collect-reviews/TemplateEditor";
import NewTemplate from "containers/pages/collect-reviews/NewTemplate";
import WidgetConfiguratorList from "containers/pages/widget-configurator/WidgetConfiguratorList";
import WidgetConfigurator from "containers/pages/widget-configurator/WidgetConfigurator";
import Logs from "containers/pages/settings/Logs";
import Users from "containers/pages/Users";
import Companies from "containers/pages/Companies";
import AutomaticCollectionSetup from "containers/pages/collect-reviews/AutomaticCollectionSetup";
import SentInvitations from "containers/pages/collect-reviews/SentInvitations";
import MyTeam from "containers/pages/settings/MyTeam";
import Announcement from "containers/pages/Announcement";
// Import pages
// const Dashboard = React.lazy(() => import("containers/pages/Dashboard"));
// const ConnectPlatforms = React.lazy(() =>
//   import("containers/pages/ConnectPlatforms")
// );
// const ConnectSource = React.lazy(() =>
//   import("containers/pages/connect-source/ConnectSource")
// );
// const ManageReviews = React.lazy(() =>
//   import("containers/pages/ManageReviews")
// );
// const CollectOverview = React.lazy(() =>
//   import("containers/pages/collect-reviews/CollectOverview")
// );
// const CreateCampaign = React.lazy(() =>
//   import("containers/pages/collect-reviews/CreateCampaign")
// );
// const AutomaticCollection = React.lazy(() =>
//   import("containers/pages/collect-reviews/AutomaticCollection")
// );
// const InvitationSettings = React.lazy(() =>
//   import("containers/pages/collect-reviews/InvitationSettings")
// );
// const TemplateEditor = React.lazy(() =>
//   import("containers/pages/collect-reviews/TemplateEditor")
// );
// const NewTemplate = React.lazy(() =>
//   import("containers/pages/collect-reviews/NewTemplate")
// );
// const WidgetConfiguratorList = React.lazy(() =>
//   import("containers/pages/widget-configurator/WidgetConfiguratorList")
// );
// const WidgetConfigurator = React.lazy(() =>
//   import("containers/pages/widget-configurator/WidgetConfigurator")
// );
// const Profile = React.lazy(() => import("containers/pages/settings/Profile"));
// const MyNotifications = React.lazy(() =>
//   import("containers/pages/settings/MyNotifications")
// );
// const BusinessInfo = React.lazy(() =>
//   import("containers/pages/settings/BusinessInfo")
// );
// const Plans = React.lazy(() => import("containers/pages/settings/Plans"));
// const Billing = React.lazy(() => import("containers/pages/settings/Billing"));
// const Feedback = React.lazy(() => import("containers/pages/settings/Feedback"));
// const Logs = React.lazy(() => import("containers/pages/settings/Logs"));
// const Users = React.lazy(() => import("containers/pages/Users"));
// const Companies = React.lazy(() => import("containers/pages/Companies"));

export function PrivateRoutes() {
  const user = useSelector((state) => state.auth.account);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/connect-platforms" component={ConnectPlatforms} />
        <Route
          exact
          path="/connect-platforms/:type"
          component={ConnectSource}
        />
        <Route exact path="/manage-reviews" component={ManageReviews} />

        {/* COLLECT REVIEWS */}
        <Route
          exact
          path="/collect-reviews/overview"
          component={CollectOverview}
        />
        <Route
          exact
          path="/collect-reviews/create-campaign"
          component={CreateCampaign}
        />
        <Route
          exact
          path="/collect-reviews/automatic-collection"
          component={AutomaticCollection}
        />
        <Route
          exact
          path="/collect-reviews/automatic-collection-setup"
          component={AutomaticCollectionSetup}
        />
        <Route
          exact
          path="/collect-reviews/invitation-settings"
          component={InvitationSettings}
        />
        <Route exact path="/template-editor" component={TemplateEditor} />
        <Route
          exact
          path="/template-editor/new-template"
          component={NewTemplate}
        />
        <Route
          exact
          path="/template-editor/new-template/:id"
          component={NewTemplate}
        />
        <Route
          exact
          path="/collect-reviews/sent-invitations"
          component={SentInvitations}
        />
        {/* WIDGET */}
        <Route exact path="/widget-list" component={WidgetConfiguratorList} />
        <Route
          exact
          path="/widget-configurator/:type/:id"
          component={WidgetConfigurator}
        />
        {/* SETTINGS */}
        <Route exact path="/settings/account" component={Profile} />
        <Route
          exact
          path="/settings/notifications"
          component={MyNotifications}
        />
        <Route exact path="/settings/business-info" component={BusinessInfo} />
        {user?._id === user?.selectedCompany?.user && (
          <Route exact path="/settings/plans" component={Plans} />
        )}
        {user?._id === user?.selectedCompany?.user && (
          <Route exact path="/settings/team" component={MyTeam} />
        )}
        {user?._id === user?.selectedCompany?.user && (
          <Route exact path="/settings/billing" component={Billing} />
        )}
        <Route exact path="/settings/feedback" component={Feedback} />
        <Route exact path="/settings/logs" component={Logs} />

        {/* USERS */}
        {user?.type === "admin" && (
          <Route exact path="/users" component={Users} />
        )}
        {user?.type === "admin" && (
          <Route exact path="/companies" component={Companies} />
        )}
        {user?.type === "admin" && (
          <Route exact path="/announcement" component={Announcement} />
        )}

        <Redirect from="/auth/login" to="/dashboard" />
        <Redirect to="/dashboard" />
        {/* <Redirect to="error/404" /> */}
      </Switch>
    </Suspense>
  );
}
