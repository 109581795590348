import ModalBasic from "components/ModalBasic";
import React from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addTag } from "store/actions/tags";
// import { useHistory } from "react-router-dom";

const initialValues = {
  tagName: "",
  populateReviews: false,
};

const AddTagModal = ({ tagModalOpen, setTagModalOpen }) => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(
        addTag(values.tagName, values.populateReviews, () => {
          setTagModalOpen(false);
          window.location.href += "/";
        })
      );
    },
  });

  return (
    <ModalBasic
      id="add-tag-modal"
      modalOpen={tagModalOpen}
      setModalOpen={setTagModalOpen}
      title="Add tag"
      style={{ zIndex: 100 }}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <div>
          {/* Modal content */}
          <div className="px-5 py-4">
            {/* Business Profile */}
            <section>
              <div className="mb-3">
                <div className="">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="name"
                  >
                    Tag name
                  </label>
                  <input
                    id="name"
                    type="text"
                    {...formik.getFieldProps("tagName")}
                    className={clsx(
                      "form-input w-full",
                      {
                        "is-invalid":
                          formik.touched.tagName && formik.errors.tagName,
                      },
                      {
                        "is-valid":
                          formik.touched.tagName && !formik.errors.tagName,
                      }
                    )}
                  />
                  {formik.touched.tagName && formik.errors.tagName && (
                    <span className="text-red-600 text-xs">
                      {formik.errors.tagName}
                    </span>
                  )}
                </div>
              </div>
            </section>

            <div className="flex items-center mt-6">
              <input
                id="populateReviews"
                type="checkbox"
                {...formik.getFieldProps("populateReviews")}
                className={clsx(
                  "form-checkbox w-6 h-6",
                  {
                    "is-invalid":
                      formik.touched.populateReviews &&
                      formik.errors.populateReviews,
                  },
                  {
                    "is-valid":
                      formik.touched.populateReviews &&
                      !formik.errors.populateReviews,
                  }
                )}
              />
              <label
                className="block text-sm font-medium ml-2"
                htmlFor="populateReviews"
              >
                Populate reviews sith tag
              </label>
            </div>
            <div className="text-xs text-gray-600 mt-2 mb-4">
              If checked added tag will automatically populate reviews that have
              this tag keyword in review description.
            </div>
            <hr className="" />
          </div>
          {/* Modal footer */}
          <div className="px-5 py-4">
            <div className="flex flex-wrap justify-end space-x-2">
              <div
                className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setTagModalOpen(false);
                }}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
                // disabled={!formik.isValid}
              >
                Add tag
              </button>
            </div>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
};

export default React.memo(AddTagModal);
