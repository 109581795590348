import React, { useEffect } from "react";
import collectOverviewIcon from "icons/collectOverviewIcon.svg";
import { Link } from "react-router-dom";
import { tailwindConfig } from "utils/Utils";
import BarChart02 from "charts/BarChart02";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignRecievers,
  getCampaignsStats,
} from "store/actions/campaign";
import { getRatingsList } from "store/actions/ratings";

const CollectOverview = () => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem("id");
  const companies = useSelector((state) => state.auth.selectedCompany);
  const businessId = companies?.id;
  // const campaignStats = useSelector((state) => state.campaign.campaignStats);
  const campaignRecievers = useSelector(
    (state) => state.campaign.campaignRecievers
  );
  const verifiedReviews = useSelector((state) => state.ratings.total);
  const avgRating = useSelector((state) => state.ratings.lastPage);

  console.log(avgRating.averageRating);

  useEffect(() => {
    dispatch(getCampaignsStats(adminId));
    dispatch(getCampaignRecievers(adminId));
    dispatch(getRatingsList(businessId));
    // eslint-disable-next-line
  }, []);

  const chartData = {
    labels: [
      "Unique customers invited",
      "Open rate",
      "Click rate",
      "Process count",
    ],
    datasets: [
      // Blue bars
      {
        label: "",
        data: [
          campaignRecievers?.length,
          verifiedReviews,
          verifiedReviews,
          campaignRecievers?.length,
          // campaignStats?.openCount,
          // campaignStats?.clickCount,
          // campaignStats?.processCount,
        ],
        backgroundColor: tailwindConfig().theme.colors.blue[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.blue[600],
        barPercentage: 0.35,
        categoryPercentage: 0.35,
      },
    ],
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="text-black font-bold text-xl sm:text-2xl mb-4">
        Overview
      </div>
      <div className="w-full bg-white border border-gray-200 py-5 px-5 sm:text-left text-center sm:py-10 sm:px-14 flex sm:flex-row flex-col items-center justify-center mb-7">
        <div className="sm:mr-10 sm:mb-0 mb-4">
          <img
            src={collectOverviewIcon}
            className="sm:h-auto h-36"
            alt="Collect Reviews"
          />
        </div>
        <div className="sm:ml-10 max-w-sm">
          <div className="font-semibold mb-3">Let’s invite some customers</div>
          <div className="mb-4 text-sm">
            Get some reviews so you can display them. Send review invitations to
            your customers now.{" "}
          </div>
          <Link
            to="/collect-reviews/create-campaign"
            className="btn bg-blue-600 hover:bg-blue-700 text-white"
          >
            Create campaign
          </Link>
        </div>
      </div>
      <div className="font-semibold mb-3.5 text-black">
        Your performance in the last 28 days
      </div>
      <div className="flex lg:flex-row flex-col">
        <div className="bg-white border border-gray-200 pb-5 p-5 lg:p-7 lg:pb-4 w-full sm:flex sm:items-center lg:flex-col lg:items-start sm:justify-between lg:mb-0 mb-4 lg:w-3/12 lg:mr-5">
          <div className="lg:mb-10 sm:mb-0 mb-4 lg:px-0 sm:px-4 text-black">
            <div className="font-semibold text-2xl mb-1">
              {campaignRecievers?.length ?? 0}
            </div>
            <div className="lg:text-base text-sm">invitations delivered</div>
          </div>
          <div className="lg:mb-10 sm:mb-0 mb-4 lg:px-0 sm:px-4 text-black">
            <div className="font-semibold text-2xl mb-1">{verifiedReviews}</div>
            <div className="lg:text-base text-sm">verified reviews</div>
          </div>
          <div className="lg:mb-10 sm:mb-0 mb-4 lg:px-0 sm:px-4 text-black">
            <div className="mb-1">
              <span className="font-semibold text-2xl">
                {/* {campaignStats?.ratingAverage ?? "N/A"} */}
                {avgRating.averageRating || "N/A"}
              </span>{" "}
              out of 5
            </div>
            <div className="lg:text-base text-sm">Trustbucket rating</div>
          </div>
        </div>
        <div className="bg-white border border-gray-200 py-5 px-2 lg:py-7 lg:px-7 w-full lg:w-9/12 max-h-96">
          <BarChart02 data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default CollectOverview;
