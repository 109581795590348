/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import Layout from "hoc/Layout/Layout";
import WidgetScreen from "containers/pages/WidgetScreen";

import ScrollTop from "hoc/ScrollTop/ScrollTop";
import Notifications from "hoc/Notifications/Notifications";
import Loader from "hoc/Loader/Loader";
import ErrorBoundaryProvider from "hoc/ErrorBoundary/ErrorBoundary";
import { getCurrentUser } from "store/actions/auth";
import UpgradePopup from "hoc/UpgradePopup/UpgradePopup";
import EmailPreviewPopup from "components/EmailPreviewPopup/EmailPreviewPopup";
// import { getLastAnnouncement } from "store/actions/announcement";

const Routes = () => {
  const isAuthorized =localStorage?.getItem("jwToken")
  // useSelector((state) => state.auth.account) ||
  //   (!window.location.href.includes("/widget/") &&
  //     localStorage?.getItem("user"));
  const isLoading = useSelector((state) => state.loader);
  const emailContent = useSelector(
    (state) => state.campaign.emailPreviewContent
  );

  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (
  //     !window.location.href.includes("/widget/") &&
  //     localStorage?.getItem("user")
  //   ) {
  //     dispatch(getCurrentUser());
  //     // dispatch(getLastAnnouncement());
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <Switch>
        <Route exact path="/widget/:id" component={WidgetScreen} />
        {!window.location.href.includes("/widget/") && (
          <ErrorBoundaryProvider>
            <ScrollTop>
              <Notifications />
              {isLoading && <Loader />}
              {emailContent && <EmailPreviewPopup />}
              <UpgradePopup />

              {!isAuthorized ? (
                // <Redirect to="/auth/login" />
                <Route>
                  <PublicRoutes />
                </Route>
              ) : (
                <>
                  <Layout>
                    <PrivateRoutes />
                  </Layout>
                </>
              )}
            </ScrollTop>
          </ErrorBoundaryProvider>
        )}
      </Switch>
    </>
  );
};

export { Routes };
