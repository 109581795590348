import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
// import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setLastAnnoucement = (payload) => {
  return {
    type: actionTypes.SET_LAST_ANNOUNCEMENT,
    payload,
  };
};

// export const getLastAnnouncement = () => {
//   return (dispatch) => {
//     axios
//       .get("announcement/latest")
//       .then((response) => {
//         // if (response.data) {
//         dispatch(setLastAnnoucement(response.data.data));
//         // }
//       })
//       .catch((error) => {});
//   };
// };

export const postAnnouncement = (message, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`announcement`, { message })
      .then((response) => {
        dispatch(successNotification(response.data.message));
        // dispatch(getLastAnnouncement());
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};
