import axios from "config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification } from "./notification";

export const setRatings = (payload) => {
  return {
    type: actionTypes.SET_RATINGS,
    payload,
  };
};

export const setRatingsTotal = (payload) => {
  return {
    type: actionTypes.SET_RATINGS_TOTAL,
    payload,
  };
};

export const setRatingsLastPage = (payload) => {
  return {
    type: actionTypes.SET_RATINGS_LAST_PAGE,
    payload,
  };
};

export const setUnrepliedTotal = (payload) => {
  return {
    type: actionTypes.SET_UNREPLIED_TOTAL,
    payload,
  };
};

export const getRatingsList = (businessId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/ratings/list?id=${businessId}`)
      .then((response) => {
        const ratings = response.data.reviews;
        const total = response.data.total;

        // Calculate the average rating
        const averageRating =
          ratings.reduce((sum, rating) => sum + rating.rating, 0) / total;

        dispatch(setRatings(response.data.reviews));
        dispatch(setRatingsTotal(response.data.total));
        dispatch(setUnrepliedTotal(response.data.total));
        dispatch(
          setRatingsLastPage({
            ratings: ratings,
            total: total,
            averageRating: averageRating.toFixed(1),
          })
        );

      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const filterRatings = (
  queryParams,
  type,
  rating,
  tags,
  reply,
  callback
) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .post("rating/filter", {
        queryParams,
        type,
        rating: rating,
        reply,
        tags,
      })
      .then(({ data }) => {
        if (queryParams.pageNumber === 1) {
          dispatch(setRatings(data?.data));
        } else {
          let previousRatings = getState().ratings?.ratings;
          let newRatings = [...previousRatings, ...data?.data];
          dispatch(setRatings(newRatings));
        }

        dispatch(setUnrepliedTotal(data?.totalNoReply));
        // dispatch(setRatingsTotal(data?.total));
        if (reply) {
          dispatch(
            setRatingsLastPage(Math.ceil(data.total / queryParams.pageSize))
          );
        } else {
          dispatch(
            setRatingsLastPage(
              Math.ceil(data.totalNoReply / queryParams.pageSize)
            )
          );
        }
        callback();
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        callback();
        dispatch(finishLoading());
      });
  };
};

export const getRatingsCallback = (
  queryParams,
  type,
  rating,
  // reply,
  callback
) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("rating/filter", {
        queryParams,
        type,
        rating,
        // reply,
      })
      .then(({ data }) => {
        callback && callback(data?.data);
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.log("ERR", err?.response?.data);
        dispatch(finishLoading());
      });
  };
};

export const postReplyTrustbucket = (id, reply, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .post("rating/trustbucket/reply", {
        id,
        reply,
      })
      .then(({ data }) => {
        callback && callback(data?.data);
        dispatch(setChangedReply(id, reply));

        dispatch(finishLoading());
      })
      .catch((err) => {
        console.log("ERR", err?.response?.data);
        dispatch(finishLoading());
      });
  };
};

export const postReplyGoogle = (reviewId, id, reply, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .post("rating/google/reply", {
        reviewId,
        comment: reply,
      })
      .then(({ data }) => {
        callback && callback(data?.data);
        dispatch(setChangedReply(id, reply));

        dispatch(finishLoading());
      })
      .catch((err) => {
        console.log("ERR", err?.response?.data);
        dispatch(finishLoading());
      });
  };
};

export const setChangedReply = (id, reply) => {
  return {
    type: actionTypes.SET_CHANGED_REPLY,
    id,
    reply,
  };
};

export const deleteReplyTrustbucket = (id, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .delete(`rating/trustbucket/reply/${id}`)
      .then(({ data }) => {
        callback && callback(data?.data);
        dispatch(setChangedReply(id, null));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.log("ERR", err?.response?.data);
        dispatch(finishLoading());
      });
  };
};

export const deleteReplyGoogle = (reviewId, id, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .delete(`rating/google/reply/${reviewId}`)
      .then(({ data }) => {
        callback && callback(data?.data);
        dispatch(setChangedReply(id, null));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.log("ERR", err?.response?.data);
        dispatch(finishLoading());
      });
  };
};
