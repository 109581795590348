import PlatformStars from "components/PlatformStars/PlatformStars";
import dayjs from "dayjs";
import { typeObject } from "helpers/getObjectByType";
import "./WidgetCard.scss";
import verifiedSVG from "icons/verified.svg";
import parse from "html-react-parser";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const WidgetCard = ({ data, widgetStyle }) => {
  console.log("data", data);
  return (
    <div
      style={{
        background: widgetStyle.backgroundColor
          ? widgetStyle.backgroundColor
          : "#efefef",
        borderColor: widgetStyle.borderColor
          ? widgetStyle.borderColor
          : "#efefef",
        borderWidth: widgetStyle.borderWeight
          ? parseInt(widgetStyle.borderWeight)
          : 0,
        padding: widgetStyle.padding ? parseInt(widgetStyle.padding) : 16,
        borderRadius: widgetStyle.borderRadius
          ? parseInt(widgetStyle.borderRadius)
          : 0,
        boxShadow:
          widgetStyle?.dropShadow === "show" &&
          "1px 1px 7px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.05)",
      }}
      className={
        "widget-card rounded" +
        (widgetStyle.mouseAnimation === "yes" ? " has-animation" : "") +
        ` bg-opacity-${parseInt(widgetStyle.backgroundOpacity)}`
      }
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div
            className="w-10 h-10 rounded-full mr-3"
            style={{ minWidth: "2.5rem" }}
          >
            {data?.image ? (
              <img
                className="w-full h-full rounded-full"
                src={data.image}
                alt=""
              />
            ) : (
              <div className="w-full h-full text-white bg-blue-500 rounded-full flex items-center font-semibold justify-center text-xl">
                {data?.name?.substring(0, 1)}
              </div>
            )}
          </div>
          <div>
            <div
              className="text-black font-bold mb-1 widget-card__name"
              style={{
                color: widgetStyle.nameColor,
                fontSize: widgetStyle.nameSize
                  ? parseInt(widgetStyle.nameSize)
                  : 18,
              }}
            >
              {data.name}
            </div>
            <div className="text-gray-500" style={{ fontSize: 13 }}>
              {dayjs(data.date).fromNow()}
            </div>
          </div>
        </div>
        {widgetStyle.platformLogos === "show" && (
          <img
            src={typeObject[data.type]?.miniImage}
            className="h-8 ml-3 object-contain"
            alt="platform"
            style={{ maxWidth: 110 }}
          />
        )}
      </div>
      <div className="flex items-center mt-2 mb-1">
        {widgetStyle.platformStars === "show" ? (
          <PlatformStars rating={data?.rating} type={data?.type} />
        ) : (
          <div className="h-2"></div>
        )}
        {data?.verified && widgetStyle.verifiedIcon === "show" && (
          <div className="flex items-center ml-2" title="Verified">
            <img src={verifiedSVG} alt="Verified" />
            {/* <div className="text-xs text-green-600 ml-2">Verified</div> */}
          </div>
        )}
      </div>
      {data?.title && (
        <div
          className={
            "text-gray-800 font-semibold mb-1 widget-card__title text-" +
            widgetStyle.textAlign
          }
          style={{
            color: widgetStyle.reviewColor,
            fontSize: 18,
            lineHeight: 1.6,
          }}
        >
          {data.title}
        </div>
      )}
      <div
        className={
          "text-gray-600 widget-card__description text-" +
          widgetStyle.textAlign +
          (data?.title ? " has-title" : "")
        }
        style={{
          color: widgetStyle.reviewColor,
          fontSize: widgetStyle.reviewSize
            ? parseInt(widgetStyle.reviewSize)
            : 15,
          lineHeight: 1.6,
        }}
      >
        {parse(data?.description || "")}
      </div>
    </div>
  );
};

export default WidgetCard;
