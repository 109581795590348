import PlatformStars from "components/PlatformStars/PlatformStars";
import WidgetPlatformLogo from "components/WidgetPlatformLogo/WidgetPlatformLogo";
import { findOverall, getStarLabel } from "helpers/findOverall";
import { getPlatformURL } from "helpers/generateUrls";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../Badge/WidgetHtmlBadge/WidgetHtmlBadge.scss";
import en from "labels/en.json";
import se from "labels/se.json";
import nl from "labels/nl.json";
import de from "labels/de.json";
import fr from "labels/fr.json";

const WidgetButton = ({ widgetStyle, data, widget = null }) => {
  const userFromStore = useSelector((state) => state.auth.account);
  const [user, setUser] = useState(userFromStore);
  const [lang, setLang] = useState(en);

  useEffect(() => {
    if (widget) {
      setUser(widget);
    } else {
      setUser(userFromStore);
    }
    // eslint-disable-next-line
  }, [widget, userFromStore]);

  useEffect(() => {
    if (widgetStyle?.language === "se") {
      setLang(se);
    } else if (widgetStyle?.language === "nl") {
      setLang(nl);
    } else if (widgetStyle?.language === "de") {
      setLang(de);
    } else if (widgetStyle?.language === "fr") {
      setLang(fr);
    } else {
      setLang(en);
    }
  }, [widgetStyle?.language]);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={getPlatformURL(
        user,
        widgetStyle?.reviewSources,
        widgetStyle?.widgetType === "button-2"
      )}
      className={"flex justify-center py-4 widget-badge"}
      style={{
        background: widget ? "transparent" : widgetStyle.outerBackgroundColor,
      }}
    >
      <div
        className={
          "flex items-center widget-badge-inner" +
          (widgetStyle.mouseAnimation === "yes" ? " has-animation" : "") +
          ` bg-opacity-${parseInt(widgetStyle.backgroundOpacity)}`
        }
        style={{
          background: widgetStyle.backgroundColor
            ? widgetStyle.backgroundColor
            : "#efefef",
          borderColor: widgetStyle.borderColor
            ? widgetStyle.borderColor
            : "#efefef",
          borderWidth: widgetStyle.borderWeight
            ? parseInt(widgetStyle.borderWeight)
            : 0,
          padding:
            widgetStyle.widgetType === "horizontal"
              ? 0
              : widgetStyle.padding
              ? parseInt(widgetStyle.padding)
              : 16,
          borderRadius: widgetStyle.borderRadius
            ? parseInt(widgetStyle.borderRadius)
            : 4,
          boxShadow:
            widgetStyle?.dropShadow === "show" &&
            "1px 1px 7px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div
          className={
            "flex items-center" +
            (widgetStyle.widgetType === "button-2" ? " pl-2" : "")
          }
        >
          <div className="flex items-center">
            {widgetStyle.widgetType === "horizontal" && (
              <div className="flex lg:items-center lg:flex-row flex-col">
                <div className="flex items-center">
                  <div
                    className="text-lg mr-3"
                    style={{
                      color: widgetStyle.nameColor,
                      fontSize: widgetStyle.nameSize
                        ? parseInt(widgetStyle.nameSize)
                        : 20,
                    }}
                  >
                    {/* Our customers say{" "} */}
                    <span className="font-bold">
                      {findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating || "N/A"}{" "}
                    </span>
                    {lang["WIDGET_OUT_OF"]} 5
                  </div>
                  <PlatformStars
                    rating={
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating
                    }
                    type={
                      widgetStyle?.reviewSources === "all"
                        ? "trustbucket"
                        : widgetStyle?.reviewSources
                    }
                  />
                </div>
                <div className="flex items-center lg:mt-0 mt-5">
                  <div
                    className="lg:ml-3.5 mr-2 text-sm"
                    style={{
                      color: widgetStyle.reviewColor,
                      fontSize: widgetStyle.reviewSize
                        ? parseInt(widgetStyle.reviewSize)
                        : 15,
                    }}
                  >
                    {lang["WIDGET_BASED_ON"]}{" "}
                    <span className="underline">
                      {
                        findOverall(
                          user,
                          widgetStyle?.reviewSources === "all"
                            ? "overall"
                            : widgetStyle?.reviewSources,
                          widget
                        ).count
                      }{" "}
                      {lang["WIDGET_REVIEWS"]}
                    </span>{" "}
                    |{" "}
                  </div>
                  <WidgetPlatformLogo
                    size="small"
                    platform={widgetStyle?.reviewSources}
                  />
                </div>
              </div>
            )}
            {widgetStyle.widgetType === "micro-combo" && (
              <>
                <div
                  className="font-bold text-lg text-bold mr-3 md:block hidden"
                  style={{
                    color: widgetStyle.nameColor,
                    fontSize: widgetStyle.nameSize
                      ? parseInt(widgetStyle.nameSize)
                      : 18,
                  }}
                >
                  {/* Our customers say{" "} */}
                  <span>
                    {getStarLabel(
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating
                    )}
                  </span>
                </div>
                <PlatformStars
                  rating={
                    findOverall(
                      user,
                      widgetStyle?.reviewSources === "all"
                        ? "overall"
                        : widgetStyle?.reviewSources,
                      widget
                    ).rating
                  }
                  type={
                    widgetStyle?.reviewSources === "all"
                      ? "trustbucket"
                      : widgetStyle?.reviewSources
                  }
                />
                <div
                  className="ml-2.5 mr-2 text-sm"
                  style={{
                    color: widgetStyle.reviewColor,
                    fontSize: widgetStyle.reviewSize
                      ? parseInt(widgetStyle.reviewSize)
                      : 14,
                  }}
                >
                  <span className="font-bold">
                    {
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).count
                    }
                  </span>{" "}
                  {lang["WIDGET_REVIEWS"]} {lang["WIDGET_ON"]}
                </div>
                <WidgetPlatformLogo
                  size="small"
                  platform={widgetStyle?.reviewSources}
                />
              </>
            )}
            {widgetStyle.widgetType === "micro-star" && (
              <>
                <div
                  className="font-bold text-lg text-bold mr-3"
                  style={{
                    color: widgetStyle.nameColor,
                    fontSize: widgetStyle.nameSize
                      ? parseInt(widgetStyle.nameSize)
                      : 18,
                  }}
                >
                  {/* Our customers say{" "} */}
                  <span>
                    {getStarLabel(
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating
                    )}
                  </span>
                </div>
                <div className="mr-5">
                  <PlatformStars
                    rating={
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating
                    }
                    type={
                      widgetStyle?.reviewSources === "all"
                        ? "trustbucket"
                        : widgetStyle?.reviewSources
                    }
                  />
                </div>
              </>
            )}
            {widgetStyle.widgetType === "micro-trustscore" && (
              <div className="flex items-center md:flex-row flex-col">
                <div className="flex items-center md:mb-0 mb-4">
                  <div
                    className="text-lg text-bold mr-3"
                    style={{
                      color: widgetStyle.nameColor,
                      fontSize: widgetStyle.nameSize
                        ? parseInt(widgetStyle.nameSize)
                        : 16,
                    }}
                  >
                    {/* Our customers say{" "} */}
                    <span className="font-bold">
                      {" "}
                      {findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating || "N/A"}
                    </span>{" "}
                    {lang["WIDGET_OUT_OF"]} 5
                  </div>
                  <PlatformStars
                    type={
                      widgetStyle?.reviewSources === "all"
                        ? "trustbucket"
                        : widgetStyle?.reviewSources
                    }
                    rating={
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).rating
                    }
                  />
                  <div
                    className={
                      "md:block hidden mr-3 " +
                      (widgetStyle?.reviewSources === "all" ||
                      widgetStyle?.reviewSources === "trustbucket" ||
                      widgetStyle?.reviewSources === "booking"
                        ? "ml-4"
                        : "ml-3")
                    }
                  >
                    {" "}
                    |{" "}
                  </div>
                </div>
                <WidgetPlatformLogo
                  size="small"
                  platform={widgetStyle?.reviewSources}
                />
              </div>
            )}
            {widgetStyle.widgetType === "button-2" && (
              <>
                <div
                  className="font-bold mr-2"
                  style={{
                    color: widgetStyle.reviewColor,
                    fontSize: widgetStyle.reviewSize
                      ? parseInt(widgetStyle.reviewSize)
                      : 14,
                  }}
                >
                  {lang["WIDGET_REVIEW_US"]}{" "}
                  <span className="font-normal">{lang["WIDGET_ON"]}</span>
                </div>
                <WidgetPlatformLogo
                  size="small"
                  platform={widgetStyle?.reviewSources}
                />
              </>
            )}
            {widgetStyle.widgetType === "button-3" && (
              <div className="flex items-center">
                <div
                  className="mr-2"
                  style={{
                    color: widgetStyle.reviewColor,
                    fontSize: widgetStyle.reviewSize
                      ? parseInt(widgetStyle.reviewSize)
                      : 15,
                    lineHeight: 1.6,
                  }}
                >
                  {lang["WIDGET_CHECK_OUT_OUR"]}{" "}
                  <span className="underline mr-1">
                    {
                      findOverall(
                        user,
                        widgetStyle?.reviewSources === "all"
                          ? "overall"
                          : widgetStyle?.reviewSources,
                        widget
                      ).count
                    }{" "}
                    reviews
                  </span>{" "}
                  |{" "}
                </div>
                <WidgetPlatformLogo
                  size="small"
                  platform={widgetStyle?.reviewSources}
                />
              </div>
            )}
            {widgetStyle.widgetType === "button-4" && (
              <div
                className="mr-2"
                style={{
                  color: widgetStyle.reviewColor,
                  fontSize: widgetStyle.reviewSize
                    ? parseInt(widgetStyle.reviewSize)
                    : 14,
                }}
              >
                <span className="font-bold">
                  {
                    findOverall(
                      user,
                      widgetStyle?.reviewSources === "all"
                        ? "overall"
                        : widgetStyle?.reviewSources,
                      widget
                    ).count
                  }
                </span>{" "}
                {lang["WIDGET_REVIEWS"]} {lang["WIDGET_ON"]}
              </div>
            )}
          </div>
        </div>
        {/* <div className={widgetStyle.widgetType === "button-2" ? " pr-2" : ""}>
          <WidgetPlatformLogo
            size="small"
            platform={widgetStyle?.reviewSources}
          />
        </div> */}
      </div>
    </a>
  );
};

export default WidgetButton;
