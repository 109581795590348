import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getRatingsCallback } from "store/actions/ratings";
import WidgetCard from "../../Cards/WidgetCard/WidgetCard";
// import "./WidgetGrid.scss";
import WidgetCardCentered from "components/WidgetConfigurator/Cards/WidgetCardCentered/WidgetCardCentered";
import { findOverall } from "helpers/findOverall";

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 10,
  sortField: "date",
  sortOrder: "desc",
};

const WidgetList = ({ widgetStyle, data }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.account);
  const [widgetReviews, setWidgetReviews] = useState([]);

  useEffect(() => {
    dispatch(
      getRatingsCallback(
        initialQueryParams,
        widgetStyle.reviewSources === "all" ? "" : widgetStyle.reviewSources,
        widgetStyle.ratingFilter === "all"
          ? ""
          : widgetStyle.ratingFilter?.split(","),
        (data) => setWidgetReviews(data)
      )
    );

    // eslint-disable-next-line
  }, [widgetStyle.reviewSources, widgetStyle.ratingFilter]);

  return (
    <div
      className="p-10 w-full mx-auto max-w-4xl"
      style={{ background: widgetStyle.outerBackgroundColor }}
    >
      {widgetStyle.footerText === "show" && (
        <div
          className="mb-4 text-center"
          style={{ color: widgetStyle.outerTextColor }}
        >
          <span className="font-bold">Trustbucket </span>
          rating score{" "}
          <span className="font-bold">{findOverall(user).rating}</span> of 5,
          based on{" "}
          <span className="font-bold underline">
            {findOverall(user).count} reviews
          </span>
        </div>
      )}
      <div className="widget-list">
        <div className="widget-list__item">
          {widgetReviews?.length > 0 &&
            widgetReviews.map((item, index) => (
              <div className="mb-4" key={index}>
                {widgetStyle.widgetType === "list-1" ? (
                  <WidgetCard data={item} widgetStyle={widgetStyle} />
                ) : (
                  <WidgetCardCentered data={item} widgetStyle={widgetStyle} />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetList;
