import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
// import * as Yup from "yup";

import Image from "icons/logo.svg";
import facebookIcon from "icons/facebook.png";
import instagramIcon from "icons/instagram.png";
import twitterIcon from "icons/twitter.svg";
import youtubeIcon from "icons/youtube.png";
import tiktokIcon from "icons/tiktok.svg";
// import GoogleMapMarker from "components/Shared/GoogleMapMarker";
import {
  changeImage,
  updateCompany,
  // updateCompanyAddress,
} from "store/actions/auth";
import QRCode from "qrcode.react";

// const registrationSchema = Yup.object().shape({
//   slug: Yup.string().required("Review page is required"),
// });


function BusinessInfoPanel() {
  const dispatch = useDispatch();
  const [displaySocial, setDisplaySocial] = useState(false);


  // const adminId = localStorage.getItem("id");
  const user = useSelector((state) => state.auth.account);
  const company = useSelector((state) => state.auth.selectedCompany);
  const businessId = company?.id;
  // eslint-disable-next-line
  const [socialLinks, setSocialLinks] = useState(
    user?.selectedCompany?.socialLinks || []
  );



  // const formik = useFormik({
  //   validationSchema: registrationSchema,
  //   initialValues: {
  //     businessName: user?.selectedCompany?.businessName,
  //     websiteURL: user?.selectedCompany?.websiteURL,
  //     businessEmail: user?.selectedCompany?.businessEmail,
  //     businessPhone: user?.selectedCompany?.businessPhone,
  //     reviewsPageLanguage: user?.selectedCompany?.reviewsPageLanguage || "en",
  //     address: user?.selectedCompany?.address,
  //     // slug: user?.selectedCompany?.slug,
  //   },
  //   enableReinitialize: true,
  //   onSubmit: (values) => {
  //     dispatch(
  //       updateCompany(businessId, {
  //         businessName: values.businessName,
  //         websiteURL: values.websiteURL,
  //         businessEmail: values.businessEmail,
  //         businessPhone: values.businessPhone,
  //         reviewsPageLanguage: values.reviewsPageLanguage,
  //         address: values.address,
  //         // slug: values.slug,
  //         // socialLinks,
  //       })
  //     );
  //     // if (values.address !== user?.selectedCompany?.address) {
  //     //   dispatch(updateCompanyAddress(values.address));
  //     // }
  //   },
  // });

  const formik = useFormik({
    // validationSchema: registrationSchema,
    initialValues: {
      businessName: company.businessName,
      websiteURL: company.websiteURL,
      businessEmail: company.email,
      businessPhone: company.phoneNumber,
      reviewsPageLanguage: company.language || "en",
      address: company.address,
      // slug: user?.selectedCompany?.slug,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const updatedCompanyData = {
        businessName: values.businessName,
        websiteURL: values.websiteURL,
        email: values.businessEmail,
        phoneNumber: values.businessPhone,
        language: values.reviewsPageLanguage,
        address: values.address,
      };

      dispatch(updateCompany(businessId, updatedCompanyData));
      // if (values.address !== user?.selectedCompany?.address) {
      //   dispatch(updateCompanyAddress(values.address));
      // }
    },
  });

  const createTrustbucketURL = () => {
    if (!user?.selectedCompany?.slug) return;

    return `https://reviews.trustbucket.io/${user?.selectedCompany?.slug}`;
  };

  const updateSocialLinks = (val, type) => {
    let filteredLinks = socialLinks.filter((el) => el?.media !== type);
    filteredLinks = [...filteredLinks, { media: type, url: val }];
    setSocialLinks(filteredLinks);
  };

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "Trustbucket_QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <>
      <div className="grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-xl sm:text-2xl text-gray-800 font-bold mb-5">
            Business details
          </h2>
          {/* Picture */}
          <section>
            <div className="flex items-center">
              <div className="mr-4">
                <img
                  className="w-20 h-20 rounded-lg object-cover"
                  src={user?.selectedCompany?.image || Image}
                  width="80"
                  height="80"
                  alt="User upload"
                />
              </div>
              <label
                className="btn-sm bg-blue-500 hover:bg-blue-600 text-white cursor-pointer"
                htmlFor="profile-image"
              >
                Change
              </label>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                id="profile-image"
                onChange={(e) => {
                  e.stopPropagation();
                  const file = e.target.files[0];
                  let bodyFormData = new FormData();
                  bodyFormData.append("file", file);
                  dispatch(changeImage(bodyFormData));
                }}
              />
            </div>
          </section>
        </div>
        <div className="">
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            className="p-6 space-y-6"
          >
            {/* Business Profile */}
            <section>
              <h2 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Business info
              </h2>
              <div className="text-sm mb-4">
                Your business info is displayed across many areas including on
                your online review page.
              </div>
              <div className="">
                <div className="xl:w-9/12">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="bus-name"
                  >
                    Business name
                  </label>
                  <input
                    id="bus-name"
                    className="form-input w-full"
                    {...formik.getFieldProps("businessName")}
                    type="text"
                    onChange={(e) => {
                      formik.setFieldValue("businessName", e.target.value);
                      formik.setFieldValue(
                        "slug",
                        e.target.value.toLowerCase().split(" ").join("-")
                      );
                    }}
                  />
                </div>
              </div>
            </section>

            <div className="xl:w-9/12">
              <div className="mb-3">
                <label className="block text-sm font-medium mb-1" htmlFor="url">
                  Website URL
                </label>
                <input
                  id="url"
                  className="form-input w-full"
                  {...formik.getFieldProps("websiteURL")}
                  type="text"
                />
              </div>
            </div>

            <div className="xl:w-9/12">
              {/* <div className="mb-3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="slug"
                >
                  Review page
                </label>
                <div className="relative">
                  <div
                    style={{
                      height: "calc(100% - 2px)",
                      top: 1,
                      left: 1,
                    }}
                    className="absolute text-xs h-full flex items-center px-2 bg-gray-200 text-gray-800 rounded-tl-sm rounded-bl-rounded-tl-sm"
                  >
                    reviews.trustbucket.io/
                  </div>
                  <input
                    id="slug"
                    style={{ paddingLeft: 155 }}
                    className="form-input w-full"
                    {...formik.getFieldProps("slug")}
                    type="text"
                  />
                </div>
                {formik.touched.slug && formik.errors.slug && (
                  <span className="text-red-600 text-xs">
                    {formik.errors.slug}
                  </span>
                )}
              </div> */}
              <p className="text-sm">
                Link to online preview page:{" "}
                <a
                  className="text-blue-600 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={createTrustbucketURL()}
                >
                  {createTrustbucketURL()}
                </a>
              </p>
              <p className="my-4 text-sm">
                Generated QR code for reviews page:
              </p>
              <QRCode id="qrCodeEl" value={createTrustbucketURL()} size={190} />
              <div
                onClick={downloadQRCode}
                className="btn cursor-pointer mt-4 bg-blue-500 text-white hover:bg-blue-600"
              >
                Download QR Code
              </div>
            </div>

            <div className="xl:w-9/12">
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Business email
              </label>
              <input
                id="email"
                className="form-input w-full"
                type="email"
                {...formik.getFieldProps("businessEmail")}
              />
            </div>

            <div className="xl:w-9/12">
              <label className="block text-sm font-medium mb-1" htmlFor="phone">
                Business phone number
              </label>
              <input
                id="phone"
                className="form-input w-full"
                type="tel"
                {...formik.getFieldProps("businessPhone")}
              />
            </div>

            <div>
              <h2 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Language settings
              </h2>
              <div className="text-sm mb-4">
                Choose the default language for the review summary page,
                notification sent to your customers or widget displayed.
              </div>
              <div className="xl:w-9/12">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="language"
                >
                  Default language for review page
                </label>
                <select
                  id="language"
                  className="form-select w-full"
                  {...formik.getFieldProps("reviewsPageLanguage")}
                >
                  <option value="en">English</option>
                  <option value="se">Swedish</option>
                  <option value="nl">Dutch</option>
                  <option value="de">German</option>
                  <option value="fr">French</option>
                </select>
              </div>
            </div>

            {/* LOCATION */}
            <div>
              <h2 className="text-lg lg:text-xl leading-snug text-gray-800 font-bold mb-1">
                Location
              </h2>
              <div className="text-sm mb-4">
                Add your company adress to help clients find you.
              </div>
            </div>
            <div className="xl:w-9/12">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="address"
              >
                Business adress
              </label>
              <input
                id="address"
                className="form-input w-full"
                type="text"
                {...formik.getFieldProps("address")}
              />
            </div>

            {/* <div className="xl:w-9/12">
              <label className="block text-sm font-medium mb-1">
                Google Map
              </label>
              {user?.selectedCompany?.address?.position?.lat &&
                user?.selectedCompany?.address?.position?.lng && (
                  <GoogleMapMarker
                    lat={user?.selectedCompany?.address?.position?.lat}
                    long={user?.selectedCompany?.address?.position?.lng}
                  />
                )}
            </div> */}

            <hr className="xl:w-9/12" />
            <div>
              <h2
                onClick={() => setDisplaySocial((p) => !p)}
                className="text-xl cursor-pointer xl:w-9/12 leading-snug text-gray-800 font-bold mb-1 flex items-center justify-between"
              >
                <span>Social links</span>
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                    fill="#94A3B8"
                  />
                </svg>
              </h2>
              <div className="text-sm mb-4">
                Add your social media links for sharing with clients.
              </div>
              <div
                className={
                  "xl:w-9/12 transition-all overflow-hidden " +
                  (displaySocial ? " max-h-96" : " max-h-0")
                }
              >
                <div className="flex items-center mb-4">
                  <img
                    src={facebookIcon}
                    alt="Facebook"
                    className="h-7 w-7 object-contain mr-3"
                  />
                  <input
                    onChange={(e) =>
                      updateSocialLinks(e.target.value, "facebook")
                    }
                    className="form-input w-full"
                    type="text"
                    value={
                      socialLinks?.find((elem) => elem.media === "facebook")
                        ?.url
                    }
                  />
                </div>
                <div className="flex items-center mb-4">
                  <img
                    src={instagramIcon}
                    alt="Instagram"
                    className="h-7 w-7 object-contain mr-3"
                  />
                  <input
                    onChange={(e) =>
                      updateSocialLinks(e.target.value, "instagram")
                    }
                    className="form-input w-full"
                    type="text"
                    value={
                      socialLinks?.find((elem) => elem.media === "instagram")
                        ?.url
                    }
                  />
                </div>
                <div className="flex items-center mb-4">
                  <img
                    src={twitterIcon}
                    alt="Twitter"
                    className="h-7 w-7 object-contain mr-3"
                  />
                  <input
                    onChange={(e) =>
                      updateSocialLinks(e.target.value, "twitter")
                    }
                    className="form-input w-full"
                    type="text"
                    value={
                      socialLinks?.find((elem) => elem.media === "twitter")?.url
                    }
                  />
                </div>
                <div className="flex items-center mb-4">
                  <img
                    src={youtubeIcon}
                    alt="Youtube"
                    className="h-7 w-7 object-contain mr-3"
                  />
                  <input
                    onChange={(e) =>
                      updateSocialLinks(e.target.value, "youtube")
                    }
                    className="form-input w-full"
                    type="text"
                    value={
                      socialLinks?.find((elem) => elem.media === "youtube")?.url
                    }
                  />
                </div>
                <div className="flex items-center mb-4">
                  <img
                    src={tiktokIcon}
                    alt="Tiktok"
                    className="h-7 w-7 object-contain mr-3"
                  />
                  <input
                    onChange={(e) =>
                      updateSocialLinks(e.target.value, "tiktok")
                    }
                    className="form-input w-full"
                    type="text"
                    value={
                      socialLinks?.find((elem) => elem.media === "tiktok")?.url
                    }
                  />
                </div>
              </div>
            </div>
            <hr className="xl:w-9/12 mt-1" style={{ marginTop: 4 }} />

            {/* Panel footer */}
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                <div className="flex self-end">
                  <button
                    type="submit"
                    className="btn bg-blue-500 hover:bg-blue-600 text-white ml-3"
                    onClick={(e) => {
                      // Ensure that this click handler doesn't prevent default or stop propagation
                      console.log("Button clicked");
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </footer>
          </form>
        </div>
      </div>
    </>
  );
}

export default BusinessInfoPanel;
