import ModalBasic from "components/ModalBasic";
import React from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addCompany } from "store/actions/auth";
import { useHistory } from "react-router-dom";

const initialValues = {
  businessName: "",
  websiteURL: "",
};

const websiteRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const registrationSchema = Yup.object().shape({
  businessName: Yup.string().required("Business Name is required"),
  websiteURL: Yup.string()
    .required("URL is required")
    .matches(websiteRegex, "Website URL is not valid"),
});

const AddBusinessModal = ({ businessModalOpen, setBusinessModalOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      const businessUserName = values.businessName.replace(/\s+/g, '');
      
      dispatch(
        addCompany(
          values.businessName,
          businessUserName,
          values.websiteURL,
          // values.slug,
          () => {
            setBusinessModalOpen(false);
            history.push("/dashboard");
          }
        )
      );
    },
  });

  return (
    <ModalBasic
      id="add-business-modal"
      modalOpen={businessModalOpen}
      setModalOpen={setBusinessModalOpen}
      title="Add business"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <div>
          {/* Modal content */}
          <div className="px-5 py-4 space-y-6">
            {/* Business Profile */}
            <section>
              <div className="text-sm mb-4">
                Your business info is displayed across many areas including on
                your online review page.
              </div>
              <div className="mb-3">
                <div className="">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="name"
                  >
                    Business name
                  </label>
                  <input
                    id="name"
                    type="text"
                    {...formik.getFieldProps("businessName")}
                    className={clsx(
                      "form-input w-full",
                      {
                        "is-invalid":
                          formik.touched.businessName &&
                          formik.errors.businessName,
                      },
                      {
                        "is-valid":
                          formik.touched.businessName &&
                          !formik.errors.businessName,
                      }
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("businessName", e.target.value);
                      formik.setFieldValue(
                        "slug",
                        e.target.value.toLowerCase().split(" ").join("-")
                      );
                    }}
                  />
                  {formik.touched.businessName &&
                    formik.errors.businessName && (
                      <span className="text-red-600 text-xs">
                        {formik.errors.businessName}
                      </span>
                    )}
                </div>
              </div>
            </section>

            <div className="">
              <label className="block text-sm font-medium mb-1" htmlFor="url">
                Website URL
              </label>
              <input
                id="url"
                type="text"
                {...formik.getFieldProps("websiteURL")}
                className={clsx(
                  "form-input w-full",
                  {
                    "is-invalid":
                      formik.touched.websiteURL && formik.errors.websiteURL,
                  },
                  {
                    "is-valid":
                      formik.touched.websiteURL && !formik.errors.websiteURL,
                  }
                )}
              />
              {formik.touched.websiteURL && formik.errors.websiteURL && (
                <span className="text-red-600 text-xs">
                  {formik.errors.websiteURL}
                </span>
              )}
            </div>
            <hr className="" />
          </div>
          {/* Modal footer */}
          <div className="px-5 py-4">
            <div className="flex flex-wrap justify-end space-x-2">
              <div
                className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setBusinessModalOpen(false);
                }}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="btn-sm bg-blue-500 hover:bg-blue-600 text-white"
                // disabled={!formik.isValid}
              >
                Create business
              </button>
            </div>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
};

export default React.memo(AddBusinessModal);
